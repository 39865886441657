//Code based on:
//https://codesandbox.io/s/8uyys?file=/demo.tsx:27-12340
import React, { useEffect, useState } from "react";
import db from "../../firebase/db";
import { Grid, IconButton, Table, TableBody, TableContainer, TablePagination, Typography, Select, MenuItem } from '@material-ui/core';

import { firestore } from "firebase";
import { BillingRecord } from "../../../shared/types";
import { PaperPage } from "@alethea-medical/react-components";
import { Order, PayrollRow, PayrollRows, SortableKeys } from "./types";

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import moment from "moment-timezone";
import { ProcessState, ProcessStatus } from "@alethea-medical/react-components";
import PayrollTableHead from "./PayrollTableHead";
import { createInitialPayrollRecord, getComparator, round, stableSort } from "./payrollFunctions";
import PayrollTableRow from "./PayrollTableRow";
import MonthPicker from "./MonthPicker";
import RefreshIcon from "@material-ui/icons/Refresh";
import ExportCSV from "./ExportCSV";
import PayrollTableTotalsRow from "./PayrollTableTotalsRow";


const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            padding: theme.spacing(2)
        },
        table: {
            overflow: 'visible',
            marginTop: theme.spacing(2)
        },
        loadingStatus: {
            marginTop: theme.spacing(1)
        }
    })
);


const Payroll = () => {
    const classes = useStyles();


    const rowsPerPageOptions = [10, 25, 50, 100];

    const [payrollRows, setPayrollRows] = useState<PayrollRows>({});

    const [fromDate, setFromDate] = useState<Date>(moment(new Date()).startOf('month').toDate());
    const [toDate, setToDate] = useState<Date>(moment(new Date()).endOf('month').toDate());

    const [cumulativePayments, setCumulativePayments] = useState<boolean>(false);
    const [province, setProvince] = useState<string>("AB");

    const [loadingRecordsState, setLoadingRecordsState] = useState<ProcessState>(ProcessState.idle);
    const [loadingRecordsError, setLoadingRecordsError] = useState("");

    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<SortableKeys>('doctorName');
    
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

    

    const createPayroll = () => {
        setPayrollRows({});

        let query = db.collection("billing").where("province", "==", province)

        if(!cumulativePayments) {
            query = query.where("hlinkResultReceivedAt", ">=", firestore.Timestamp.fromDate(fromDate))
        }

        query.where("hlinkResultReceivedAt", "<=", firestore.Timestamp.fromDate(toDate)).where("status", "in", ["accepted", "paid", "refused", "paymentPending", "paymentSubmitted", "paymentFailed"]).get()
        .then((snapshot) => {
            const rows: PayrollRows = {}
            console.log(`Retrieved ${snapshot.size} records`);

            snapshot.docs.forEach((doc) => {
                const record = doc.data() as BillingRecord
                const key = record.billingDoctorUid;

                //Initialize row
                if(rows[key] === undefined) {
                    rows[key] = createInitialPayrollRecord(key, record);
                }

                //Count total number of accepted and rejected communications
                if(record.type === "Communications" || record.type === "Forms") {
                    if(record.status === "refused") 
                        rows[key].totalCommunicationRejections += 1;
                    else
                        rows[key].totalCommunications += 1;
                }
                //Count total number of accepted and rejected econsults
                else {
                    if(record.status === "refused") 
                        rows[key].totalEconsultRejections += 1;
                    else
                        rows[key].totalEconsults += 1;
                }

                //Calculate total expected amount for all accepted records
                if(record.status !== "refused") {
                    rows[key].totalExpectedBilling += record.expectedAssessedAmt;

                    //Calculate the amount we were actually paid
                    if(record.assessedAmt !== undefined) {
                        rows[key].totalBilling += record.assessedAmt;
                    }
                }


                //Calculate how much we should pay the doctor based on the amount recorded in the record
                if(record.status === "accepted" || record.status === "paymentFailed") {
                    rows[key].totalOwedToDoctor += record.payToDoctor;
                    rows[key].recordsToPay.push(doc.id);
                }

                if(record.status === "paymentPending"){
                    rows[key].totalAmountPending += record.payToDoctor;
                    rows[key].recordsToPay.push(doc.id);
                }

                if(record.status === "paymentSubmitted"){
                    rows[key].totalAmountSubmitted += record.payToDoctor;
                    rows[key].recordsToPay.push(doc.id);
                }

                if(record.status === "paymentFailed"){
                    rows[key].totalAmountFailed += record.payToDoctor;
                    rows[key].recordsToPay.push(doc.id);
                }

                //Calculate how much we already paid to the doctor
                if(record.status === "paid") {
                    rows[key].totalPaidToDoctor += record.payToDoctor;
                }
            })

            Object.values(rows).forEach((r) => {
                r.totalBilling = round(r.totalBilling);
                r.totalExpectedBilling = round(r.totalExpectedBilling);
                r.totalOwedToDoctor = round(r.totalOwedToDoctor);
                r.totalPaidToDoctor = round(r.totalPaidToDoctor);
            })

            setPage(0);
            setPayrollRows(rows);

            setLoadingRecordsState(ProcessState.success);
            setTimeout(() => {
                setLoadingRecordsState(ProcessState.idle);
            }, 2000)

        })
        .catch((error: Error) => {
            setLoadingRecordsState(ProcessState.error);
            setLoadingRecordsError(error.message);
            console.error(error.message);
        })
    }

    
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: SortableKeys) => {
        const isAsc = orderBy === property && order === 'desc';
        setOrder(isAsc ? 'asc' : 'desc');
        setOrderBy(property);
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleMonthChange = (from: Date, to: Date) => {
        setFromDate(from);
        setToDate(to);
    }

    const handleCumulativeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        setCumulativePayments(event.target.checked)
    }

    useEffect(() => {
        createPayroll();
    }, [fromDate, toDate, cumulativePayments, province])

    return (
        <PaperPage className={classes.root}>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item>
                            <IconButton onClick={createPayroll}>
                                <RefreshIcon/>
                            </IconButton>
                        </Grid>
                        <Grid item style={{paddingTop: "3px"}}>
                            <Typography variant="subtitle1" style={{fontSize: "12px"}}>Province</Typography>
                            <Select
                                label="Province"
                                value={province}
                                onChange={(e: any) => {
                                    setProvince(e.target.value)
                                }}
                            >
                                <MenuItem value={"AB"}>AB</MenuItem>
                                <MenuItem value={"BC"}>BC</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item>
                            <MonthPicker label="Billing Period" fromDate={fromDate} setMonth={handleMonthChange}/>
                        </Grid>
                        {/* disabled due to slow performance */}
                        {/* <Grid item> 
                            <FormControlLabel
                                control={<Checkbox checked={cumulativePayments} onChange={handleCumulativeChange} />}
                                label="Cumulative Billing"
                            />
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={1}>

                        <Grid item>
                            <ExportCSV fromDate={fromDate} payrollRows={payrollRows}/>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>
                    

            
            <div className={classes.loadingStatus}>
                <ProcessStatus state={loadingRecordsState} errorMessage={loadingRecordsError} successMessage={`Fetched ${Object.keys(payrollRows).length} rows`} useSnackbar setState={setLoadingRecordsState}/>
            </div>      
            
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'small'}
                    stickyHeader
                >
                    <PayrollTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        <PayrollTableTotalsRow rows={payrollRows}/>
                        {stableSort(Object.values(payrollRows), getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row: PayrollRow) => {    
                            return (
                                <PayrollTableRow row={row} key={`payroll_row_${row.key}`} fromDate={fromDate} province={province} refresh={createPayroll}/>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component={"div"}
                count={Object.keys(payrollRows).length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />         
        </PaperPage>
    );
}

export default Payroll;