import { useEffect, useState } from "react";
import { Province } from "../../../shared/types";
import getAvailableBillingCodes, { BillingCodesDict } from "./getAvailableBillingCodes";

interface useBillingCodesProps {
    province: Province    
}

const useBillingCodes = ({ province }: useBillingCodesProps) => {
    const [billingCodes, setBillingCodes] = useState<BillingCodesDict>({})
    
    useEffect(() => {
        getAvailableBillingCodes(province)
        .then(setBillingCodes)
    }, [province])


    return {
        billingCodes
    }
}

export default useBillingCodes;