import React from 'react';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import { PayrollRow, PayrollRows } from "./types";
import { parse } from "json2csv";
import { saveAs } from "file-saver";

interface ExportCSVProps {
    fromDate: Date,
    payrollRows: PayrollRows
}
const ExportCSV = ({ fromDate, payrollRows }: ExportCSVProps) => {

    const removeField = (fields: (keyof PayrollRow)[], fieldToRemove: keyof PayrollRow) => {
        const index = fields.indexOf(fieldToRemove);
        if (index > -1) {
            fields.splice(index, 1);
        }
    }

    const exportAsCSV = () => {        
        if(Object.values(payrollRows).length > 0) {
            const fields = Object.keys(Object.values(payrollRows)[0]) as (keyof PayrollRow)[];

            removeField(fields, "key");
            removeField(fields, "recordsToPay");
    
            let csv = parse(Object.values(payrollRows).sort((a, b) => {
                return b.doctorName < a.doctorName ? 1 : -1
            }), {
                fields: fields
            });
            let fileName = `billing_${fromDate.toLocaleString('default', { month: 'long' })}.csv`;
            
            // Create a blob of the data
            let fileToSave = new Blob([csv], {type: "text/plain;charset=utf-8"});
            saveAs(fileToSave, fileName);
        }
    }

    return (
        <div>
            <Tooltip title="Export as a .csv file">
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<GetAppIcon/>}
                    disabled={Object.values(payrollRows).length === 0}
                    onClick={exportAsCSV}
                >
                    Export
                </Button>
            </Tooltip>
        </div>
    );
}

export default ExportCSV;