//Update every release
//Format is x.y.z.yyyy.mm.dd

//x.y.z should match package.json version
//yyyy.mm.dd should match day of release
const versionStrings = {
    //Official release version string
    version: "4.15.0.2024.08.29",
    //Increment to keep track of development builds
    devBuild: "1",
};

export default versionStrings;

