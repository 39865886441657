import React from "react";
import { TableRow, TableCell, TableHead, TableSortLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SortableKeys, Order } from "./types";


interface HeadCell {
	id: SortableKeys;
	label: string;
}


export const headCells: HeadCell[] = [
	{ id: 'doctorName', label: 'Doctor Name' },
	{ id: 'totalEconsults', label: 'Total eConsults' },
	{ id: 'totalEconsultRejections', label: "Total Econsult Rejections"},
	{ id: 'totalCommunications', label: 'Total Communications'},
	{ id: 'totalCommunicationRejections', label: "Total Communications Rejections"},
	{ id: 'totalBilling', label: "Total Billing"},
	{ id: 'totalExpectedBilling', label: "Expected Billing"},
	{ id: 'totalOwedToDoctor', label: 'Total Owed to Doctor' },
	{ id: 'totalPaidToDoctor', label: "Total Paid to Doctor"},
	{ id: 'totalAmountPending', label: 'Total payments Pending'},
	{ id: 'totalAmountSubmitted', label: 'Total Payments Submitted to RBC'},
	{ id: 'totalAmountFailed', label: 'Total Payments Failed'},
];

interface PayrollTableHeadProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: SortableKeys) => void;
    order: Order,
    orderBy: string;
}

const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	sortingBy: {
		color: theme.palette.primary.main
	},
	notSortingBy: {
		color: theme.palette.grey[600]
	}
}));

const PayrollTableHead = ({ order, orderBy, onRequestSort }: PayrollTableHeadProps) => {
    const classes = useStyles(); 

    const createSortHandler = (property: SortableKeys) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };  


	return (
		<TableHead>
			<TableRow>
				<TableCell
					key={'viewBilling'}
					align={'center'}
					padding={'normal'}
					className={classes.notSortingBy}
				>
					View
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={'left'}
						padding={'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							
							{orderBy === headCell.id ? (
								<>
									<span className={classes.sortingBy}>{headCell.label}</span>
									<span className={classes.visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</span>
								</>
							) : 
								<>
									<span className={classes.notSortingBy}>{headCell.label}</span>
								</>
							}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell
					key={'markAsPaid'}
					align={'center'}
					padding={'normal'}
					className={classes.notSortingBy}
				>
					Mark as Paid
				</TableCell>
			</TableRow>
		</TableHead>
	);
}

export default PayrollTableHead;