import {Typography, List, ListItem, Grid} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PaperPage, ProcessStatus, useProcessState } from "@alethea-medical/alethea-components";
import { useState } from "react";
import moment from "moment-timezone";
import AveiroReport from "./AveiroReport";
import DatePicker from '../Billing/DatePicker';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(2)
        },
        item: {
            padding: theme.spacing(4),
            backgroundColor: "white",
            marginBottom: theme.spacing(2),
        },
    }),
);

const Reports = () => {
    const classes = useStyles();

    const [fromDate, setFromDate] = useState<Date>(moment(new Date()).startOf('month').toDate());
    const [toDate, setToDate] = useState<Date>(moment(new Date()).endOf('month').toDate());
    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({});

    return (
        <PaperPage >
            <div className={classes.root}>
                
                <Grid container justifyContent="space-between" spacing={3}>
                    <Grid item>
                        <Typography variant="h4">Reports</Typography>
                    </Grid>
                    <Grid item>
                        <DatePicker
                            fromDate={fromDate}
                            toDate={toDate}
                            handleDateFromChange={setFromDate}
                            handleDateToChange={setToDate}
                        />
                        <Typography variant="subtitle1">Reports are generated in Mountain Time</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ProcessStatus state={processState} errorMessage={processErrorMessage} successMessage={"Success"} useSnackbar setState={setProcessState}/>
                    </Grid>                           
                </Grid>

                <Grid item xs={12}>
                    <List>
                        <ListItem className={classes.item} divider>
                            <AveiroReport 
                                fromDate={fromDate} 
                                toDate={toDate} 
                                setProcessState={setProcessState}
                                errorHandler={errorHandler}
                            />
                        </ListItem>
                    </List>
                            
                </Grid>

                
            </div>
        </PaperPage >
    );
}

export default Reports;